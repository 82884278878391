import { render, staticRenderFns } from "./ResetPasswordComplete.vue?vue&type=template&id=5a7b1594&scoped=true"
import script from "./ResetPasswordComplete.vue?vue&type=script&lang=ts"
export * from "./ResetPasswordComplete.vue?vue&type=script&lang=ts"
import style0 from "./ResetPasswordComplete.vue?vue&type=style&index=0&id=5a7b1594&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a7b1594",
  null
  
)

export default component.exports