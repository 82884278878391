
import { Component, Vue } from "vue-property-decorator";
import NotAuthHeader from "@/components/NotAuthHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";

@Component({
  name: "ResetPasswordComplete",
  components: {
    NotAuthHeader,
    ManagementFooter,
  },
})
export default class ResetPasswordComplete extends Vue {
  private back() {
    this.$router.push({ name: "Login" });
  }
}
